// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirmation-js": () => import("./../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-password-reset-js": () => import("./../src/pages/password/reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-reconfirmation-js": () => import("./../src/pages/reconfirmation.js" /* webpackChunkName: "component---src-pages-reconfirmation-js" */),
  "component---src-pages-referrals-js": () => import("./../src/pages/referrals.js" /* webpackChunkName: "component---src-pages-referrals-js" */),
  "component---src-templates-blog-list-page-js": () => import("./../src/templates/BlogListPage.js" /* webpackChunkName: "component---src-templates-blog-list-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-discovery-article-js": () => import("./../src/templates/DiscoveryArticle.js" /* webpackChunkName: "component---src-templates-discovery-article-js" */),
  "component---src-templates-discovery-video-js": () => import("./../src/templates/DiscoveryVideo.js" /* webpackChunkName: "component---src-templates-discovery-video-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-learn-page-js": () => import("./../src/templates/LearnPage.js" /* webpackChunkName: "component---src-templates-learn-page-js" */),
  "component---src-templates-standard-page-js": () => import("./../src/templates/StandardPage.js" /* webpackChunkName: "component---src-templates-standard-page-js" */)
}

