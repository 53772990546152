import { getIsolatedQuery } from 'gatsby-source-graphql-universal';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { pathToRegexp, match as matchRegex } from 'path-to-regexp';
import Prismic from 'prismic-javascript';
import React from 'react';
import traverse from 'traverse';
import { getCookies } from 'gatsby-source-prismic-graphql/utils';
import { fieldName, typeName } from '../utils';
import { createLoadingScreen } from 'gatsby-source-prismic-graphql/utils/createLoadingScreen';
import { getApolloClient } from 'gatsby-source-prismic-graphql/utils/getApolloClient';
import { parseQueryStringAsJson } from 'gatsby-source-prismic-graphql/utils/parseQueryString';

const queryOrSource = (obj) => {
  if (typeof obj === 'string') {
    return obj.replace(/\s+/g, ' ');
  } else if (obj.source) {
    return String(obj.source).replace(/\s+/g, ' ');
  }
  return null;
};

const stripSharp = (query) => {
  return traverse(query).map(function (x) {
    if (
      typeof x === 'object' &&
      x.kind == 'Name' &&
      this.parent &&
      this.parent.node.kind === 'Field' &&
      x.value.match(/Sharp$/) &&
      !x.value.match(/.+childImageSharp$/)
    ) {
      this.parent.remove();
    }
  });
};

export class WrapPage extends React.PureComponent {
  constructor() {
    super(...arguments);
    this.state = {
      data: this.props.data,
      loading: false,
      error: null,
    };
    this.keys = ['uid', 'id', 'lang'];
    this.load = ({ variables = {}, query, fragments = [], ...rest } = {}) => {
      if (!query) {
        query = this.getQuery();
      } else {
        query = queryOrSource(query);
      }
      fragments.forEach((fragment) => {
        query += queryOrSource(fragment);
      });
      const keys = [
        ...(this.props.options.passContextKeys || []),
        ...this.keys,
      ];
      variables = { ...pick(this.params, keys), ...variables };
      return getApolloClient(this.props.options).then((client) => {
        return client.query({
          query: stripSharp(getIsolatedQuery(query, fieldName, typeName)),
          fetchPolicy: 'no-cache',
          variables,
          ...rest,
        });
      });
    };
  }

  get params() {
    const params = { ...this.props.pageContext };
    const keys = [];
    const re = pathToRegexp(get(this.props.pageContext, 'matchPath', ''), keys);
    const match = re.exec(get(this.props, 'location.pathname', ''));
    const matchFn = matchRegex(get(this.props.pageContext, 'matchPath', ''), {
      decode: decodeURIComponent,
    });
    const pathParams = (() => {
      const res = matchFn(get(this.props, 'location.pathname', ''));
      return res ? res.params : {};
    })();
    const qsParams = (() => {
      const qsValue = String(get(this.props, 'location.search', '?')).substr(1);
      return parseQueryStringAsJson(qsValue);
    })();
    return Object.assign(params, qsParams, pathParams);
  }

  getQuery() {
    const child = this.props.children;
    let query =
      queryOrSource(get(this.props.pageContext, 'appRootQuery')) || '';

    if (child && child.type) {
      query = this.useChildQueryIfExists(child, query);
    }

    return query;
  }

  useChildQueryIfExists(child, query) {
    if (child.type.query) {
      query = queryOrSource(child.type.query) || '';
    }

    if (child.type.fragments && Array.isArray(child.type.fragments)) {
      child.type.fragments.forEach((fragment) => {
        query += queryOrSource(fragment);
      });
    }

    return query;
  }

  componentDidMount() {
    const { pageContext, options } = this.props;
    const cookies = getCookies();
    const hasCookie =
      cookies.has(Prismic.experimentCookie) ||
      cookies.has(Prismic.previewCookie);
    if (pageContext.appRootQuery && options.previews !== false && hasCookie) {
      const closeLoading = createLoadingScreen();
      this.setState({ loading: true });
      this.load()
        .then((res) => {
          this.setState({
            loading: false,
            error: null,
            data: { ...this.state.data, appPrismic: res.data },
          });
          closeLoading();
        })
        .catch((error) => {
          this.setState({ loading: false, error });
          console.error(error);
          closeLoading();
        });
    }
  }

  render() {
    // Check if the child is the default prismic WrapPage component.
    if (this.props.pageContext.rootQuery) {
      // Since child is the default prismic WrapPage component,
      // then we just want to pass the appPrismic prop into
      // the default wrapped component.
      const element = this.props.children;
      const children = element.props.children;

      return React.cloneElement(element, {
        ...element.props,
        children: React.cloneElement(children, {
          ...children.props,
          appPrismic: {
            options: this.props.options,
            loading: this.state.loading,
            error: this.state.error,
            load: this.load,
          },
        }),
      });
    } else {
      const children = this.props.children;

      return React.cloneElement(children, {
        ...children.props,
        appPrismic: {
          options: this.props.options,
          loading: this.state.loading,
          error: this.state.error,
          load: this.load,
        },
        data: this.state.data,
      });
    }
  }
}
