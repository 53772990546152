function linkResolver(doc) {
  switch (doc.type) {
    case 'standard_page':
      return `/${doc.uid}`;

    case 'blog_list_page':
      return `/${doc.uid}`;

    case 'blog_post':
      return `/blog/${doc.uid}`;

    case 'learn_page':
      return `/${doc.uid}`;

    case 'homepage':
      return `/${doc.uid}`;

    case 'discovery_article':
      return `/learn/${doc.uid}`;

    case 'discovery_video':
      return `/learn/${doc.uid}`;
  }

  // Backup for all other types
  return `/${doc.uid}`;
}

export default linkResolver;
